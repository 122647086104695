<template>
  <div>
    <!-- <div v-loading="loading" element-loading-text="数据加载中..."> -->
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">合同管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">合同签署人</span>
      </div>
    </div>
    <div style="float:right;margin: 10px;">
      <el-button type="primary" @click="addContractUser()">新增签署人</el-button>
    </div>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="合同签署人" label-class-name="arrClass">
        <template slot-scope="scope">
          <span v-if="scope.row.user">
            {{
            scope.row.user.name ||
            scope.row.user.nickname
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="角色">
        <template slot-scope="scope">
          <span v-if="scope.row.role">{{scope.row.role.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号">
        <template slot-scope="scope">
          <span v-if="scope.row.user">{{ scope.row.user.username }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="企业员工状态" :render-header="renderHeader">
        <template slot-scope="scope">
          <span class="Authenticated" v-if="scope.row.isAuthenticated">已加入</span>
          <span class="isAuthenticated" v-if="!scope.row.isAuthenticated">未加入</span>
        </template>
      </el-table-column>-->
      <el-table-column label="印章授权状态">
        <template slot-scope="scope">
          <span class="Authenticated" v-if="scope.row.authStatus==='-1'">待签署</span>
          <span class="Authenticated" v-if="scope.row.authStatus==='1'">生效</span>
          <span class="Authenticated" v-if="scope.row.authStatus==='0'">失效</span>
          <span class="Authenticated" v-if="scope.row.authStatus==='2'">已删除</span>
          <span class="Authenticated" v-if="scope.row.authStatus==='3'">待生效</span>
        </template>
      </el-table-column>
      <el-table-column label="授权效期">
        <template slot-scope="scope">
          <span>{{ scope.row.effectiveTime | dateVal }}到{{scope.row.expireTime | dateVal}}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <!-- <template slot-scope="scope">
          <el-popconfirm title="确定对张三印章授权？" @confirm="confirmAuthorization(scope.row)">
            <el-button type="text" slot="reference">印章授权</el-button>
        </el-popconfirm>-->
        <template slot-scope="scope">
          <el-popconfirm
            :title="`确定对此人解除授权`"
            @confirm="cancelAuthorization(scope.row)"
            style="margin:0 10px"
          >
            <el-button type="text" slot="reference">解除授权</el-button>
          </el-popconfirm>
          <el-button type="text" @click="editAuthorization(scope.row)">编辑授权</el-button>
          <el-button
            type="text"
            v-if="scope.row.authStatus==='-1'"
            @click="authorization(scope.row)"
          >点击授权</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <addDialog
      :dialogVisible.sync="addDialogVisible"
      :code.sync="code"
      :title.sync="title"
      @func="addClose()"
    ></addDialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const contractSingerList = api()('contractSinger.list.json')
export const contractSingerSingerAuthDel = api()('contractSinger.singerAuthDel.json')
export const contractSingercheckOrgAuth = api()('contractSinger.check_orgAuth.json');
export const contractSingerGetOrgAuthUrl = api()('contractSinger.getOrgAuthUrl.json');

export default {
  components: {
    addDialog: () => import('./add'),
  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      list: [
        { name: '111', code: '2231sad', }
      ],
      addDialogVisible: false,
      title: '',
      code: ''
      // loading: true,
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      const params = {

        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      }
      const res = await contractSingerList(params)
      this.list = res.list
      this.total = res.total
      console.log(res)
    },
    confirmAuthorization (val) {
      console.log(val, 222);
    },
    async cancelAuthorization (val) {

      const params = {
        code: val.code
      }
      const res = await contractSingerSingerAuthDel(params)
      this.$message({
        message: '解绑成功',
        type: 'success'
      });
      this.list = []
      this.loadData();
      console.log(res);

    },
    editAuthorization (val) {

      this.addDialogVisible = true
      this.title = '编辑签署人'
      this.code = val.code
    },
    // renderHeader () {
    //   return (<div style="position:relative;left:2px;">
    //     <span>日期</span>
    //     <el-tooltip class="item" effect="dark" content="" placement="bottom">
    //       <div slot="content">在e签宝内将员T加入到企业成员，员工成为关联企业<br />的内部员工后，企业才能给员T授权使用印章</div>
    //       <span>
    //         <i style="margin:0 4px;color:#999" class="el-icon-question"></i>
    //       </span>
    //     </el-tooltip>
    //   </div >)
    // },
    authorization (row) {
      window.open(`${row.singUrl}`)
    },
    async addContractUser () {
      contractSingercheckOrgAuth().then((res) => {
        if (!res.result) {
          console.log(res);
          this.$message.info(res.msg)
          contractSingerGetOrgAuthUrl({ redirectUrl: window.location.href }).then((url) => {
            window.location.href = url;
          })


        }
        else {
          this.addDialogVisible = true
          this.title = '新增签署人'
          this.code = ''
        }
      })

    },
    handleSizeChange (val) {
      // this.loading = true;
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      // this.loading = true;
      this.pageNumber = val;
      this.loadData();
    },
    addClose () {

      this.loadData();


    },

  },
}
</script>

<style  >
/* .el-popover {
  background-color: #999 !important;
} */
</style>